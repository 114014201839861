import { FIELDS } from "lib"


export const CONCILIATIONS_TABLE_COLUMNS = [
    { id: FIELDS.docDate.key, label: FIELDS.docDate.name },
    { id: FIELDS.refDocNumber.key, label: FIELDS.refDocNumber.name },
    { id: FIELDS.docHeaderText.key, label: FIELDS.docHeaderText.name },
    { id: FIELDS.total.key, label: FIELDS.total.name },
    { id: FIELDS.totalSinImpuestos.key, label: FIELDS.totalSinImpuestos.name },
    { id: FIELDS.iva.key, label: FIELDS.iva.name },
    { id: FIELDS.assign.key, label: FIELDS.assign.name },
    { id: FIELDS.itemText.key, label: FIELDS.itemText.name },
    { id: FIELDS.reference1.key, label: FIELDS.reference1.name },
    { id: FIELDS.accessKey.key, label: FIELDS.accessKey.name },
    { id: FIELDS.rucReceptor.key, label: FIELDS.rucReceptor.name }
]