import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
    ConciliationCompleteForm,
    ConciliationFilters,
    LabelDisplayedRows
} from "components"

import { useLoading } from "hooks"
import {
    BANK_CONSILIATION,
    CONCILIATIONS_TABLE_COLUMNS,
    FIELDS,
    getConciliationsService,
    MEDIUM_ROWS_PER_PAGINATION_OPTIONS
} from "lib"
import React from "react"
import { Helmet } from "react-helmet"
import { IBankDashboardResponse, IMessageConfig, LOADING_OPTION_CONCILIATIONS } from "types"

const ConciliationDashboard = () => {

    const [conciliations, setConciliations] = React.useState<IBankDashboardResponse>()
    const [page, setPage] = React.useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(MEDIUM_ROWS_PER_PAGINATION_OPTIONS[0])

    const { loading, handleChangeLoading, handleCloseLoad } = useLoading<LOADING_OPTION_CONCILIATIONS>()
    const initialLoading = loading.includes(LOADING_OPTION_CONCILIATIONS.LOADING_CONCILIATIONS)
    const [initDate, setInitDate] = React.useState<Date | null>(null)
    const [endDate, setEndDate] = React.useState<Date | null>(null)
    const [message, setMessage] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [openFilters, setOpenFilters] = React.useState<boolean>(false)
    const [openForm, setOpenForm] = React.useState<boolean>(false)
    const [selectedConciliation, setSelectedConciliation] = React.useState<number>(0)
    const [formState, setFormState] = React.useState({ refDocNumber: '', docHeaderText: '', incomplete: false })
    const refDocNumber = formState.refDocNumber
    const docHeaderText = formState.docHeaderText
    const incomplete = formState.incomplete

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target
        setFormState((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }))
    }

    const handleSetPage = (_page: number) => {
        setPage(_page)
    }

    const handleSetRowsPerPage = (_rowsPerPage: number) => {
        setRowsPerPage(_rowsPerPage)
    }

    const clearSearchsStates = () => {
        setFormState({ refDocNumber: '', docHeaderText: '', incomplete: false })
        setOpenFilters(false)
        setInitDate(null)
        setEndDate(null)
    }

    const clearInputDates = () => {
        setInitDate(null)
        setEndDate(null)
    }

    const hanldeClearSearch = async () => {
        try {
            handleChangeLoading(LOADING_OPTION_CONCILIATIONS.LOADING_CLEAR_SEARCH_)
            clearSearchsStates()
            await handleGetConciliations(0, rowsPerPage, '', '', incomplete, null, null)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION_CONCILIATIONS.LOADING_CLEAR_SEARCH_)
        }
    }

    const handleOpenEdit = (conciliationId: number) => {
        setOpenForm(true)
        setSelectedConciliation(conciliationId)
    }

    const handleClose = () => {
        setOpenForm(false)
        setSelectedConciliation(0)
    }

    const addSuccesMessage = (message: string) => {
        setMessage({
            open: true,
            message,
            severity: "success"
        })
    }

    const addErrorMessage = (message: string) => {
        setMessage({
            open: true,
            message,
            severity: "error"
        })
    }

    const handleGetConciliations = async (
        page: number,
        rowsPerPage: number,
        refDocNumber: string,
        docHeaderText: string,
        incomplete: boolean,
        initDate: Date | null,
        endDate: Date | null
    ) => {
        try {
            handleChangeLoading(LOADING_OPTION_CONCILIATIONS.LOADING_CONCILIATIONS)
            const response = await getConciliationsService(
                page,
                rowsPerPage,
                refDocNumber,
                docHeaderText,
                incomplete,
                initDate,
                endDate
            )
            setConciliations(response)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION_CONCILIATIONS.LOADING_CONCILIATIONS)
        }
    }

    const handleChangeEndDate = (finPromo: Date | null) => {
        setEndDate(finPromo)
    }

    const handleChangeInitDate = (inicioPromo: Date | null) => {
        setInitDate(inicioPromo)
    }


    const handleSearchByDate = async (inicioPromo: Date | null, finPromo: Date | null) => {
        if (initDate !== null && finPromo) {
            setEndDate(finPromo)
            await handleGetConciliations(
                page,
                rowsPerPage,
                refDocNumber,
                docHeaderText,
                incomplete,
                initDate,
                finPromo
            )
        }

        if (endDate !== null && inicioPromo) {
            setInitDate(inicioPromo)
            await handleGetConciliations(
                page,
                rowsPerPage,
                refDocNumber,
                docHeaderText,
                incomplete,
                inicioPromo,
                endDate
            )
        }

    }

    const handleSearch = async () => {
        try {
            handleChangeLoading(LOADING_OPTION_CONCILIATIONS.LOADING_CONCILIATIONS)
            handleSetPage(0)
            await handleGetConciliations(0, rowsPerPage, refDocNumber, docHeaderText, incomplete, initDate, endDate)
        } catch (error) {
            console.log("Error", error)
        } finally {
            handleCloseLoad(LOADING_OPTION_CONCILIATIONS.LOADING_CONCILIATIONS)
        }
    }
    const handleKeyUp = async (event: React.KeyboardEvent<HTMLDivElement>, option: 'RefDocNumber' | 'DocHeaderText') => {
        if (event.key === 'Enter') {
            if (option === 'RefDocNumber') {
                if (refDocNumber !== undefined || refDocNumber === '') {
                    await handleSearch()
                } else {
                    await hanldeClearSearch()
                }
            }
            if (option === 'DocHeaderText') {
                if (docHeaderText !== undefined || docHeaderText === '') {
                    await handleSearch()
                } else {
                    await hanldeClearSearch()
                }
            }

        }
    }

    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        handleSetPage(newPage)
        await handleGetConciliations(
            page,
            rowsPerPage,
            refDocNumber,
            docHeaderText,
            incomplete,
            initDate,
            endDate
        )
    }

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleSetRowsPerPage(parseInt(event.target.value, 10))
        handleSetPage(0)
        await handleGetConciliations(
            0,
            parseInt(event.target.value, 10),
            refDocNumber,
            docHeaderText,
            incomplete,
            initDate,
            endDate
        )

    }

    React.useEffect(() => {
        handleGetConciliations(
            page,
            rowsPerPage,
            refDocNumber,
            docHeaderText,
            incomplete,
            initDate,
            endDate
        )
    }, [])

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${BANK_CONSILIATION}`}</title>
            </Helmet>
            <Box className="flex items-center">
                <form className="flex flex-col w-full">
                    <Box className="flex items-center justify-between w-full">
                        <Box className="flex">
                            <TextField
                                size="small"
                                name="refDocNumber"
                                value={refDocNumber}
                                onChange={handleChange}
                                variant="outlined"
                                style={{ width: 250 }}
                                placeholder={"Ref Doc Number..."}
                                classes={{ root: "search-text-field" }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Icon style={{ fontSize: "0.75em" }}>search</Icon></InputAdornment>,
                                    endAdornment: refDocNumber && <InputAdornment position="end">
                                        <IconButton onClick={() => setFormState((prev) => ({ ...prev, refDocNumber: '' }))} size="small"><Icon style={{ fontSize: "0.75em" }}>close</Icon></IconButton></InputAdornment>
                                }}
                                onKeyUp={async (event) => { await handleKeyUp(event, 'RefDocNumber') }}
                            />

                            <Box className="ml-2">
                                <TextField
                                    size="small"
                                    name="docHeaderText"
                                    value={docHeaderText}
                                    onChange={handleChange}
                                    variant="outlined"
                                    style={{ width: 250 }}
                                    placeholder={"Doc Header Text..."}
                                    classes={{ root: "search-text-field" }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Icon style={{ fontSize: "0.75em" }}>search</Icon></InputAdornment>,
                                        endAdornment: docHeaderText && <InputAdornment position="end"><IconButton onClick={() => setFormState((prev) => ({ ...prev, docHeaderText: '' }))} size="small"><Icon style={{ fontSize: "0.75em" }}>close</Icon></IconButton></InputAdornment>
                                    }}
                                    onKeyUp={async (event) => { await handleKeyUp(event, 'DocHeaderText') }}
                                />
                            </Box>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="incomplete"
                                        checked={incomplete}
                                        onChange={handleChange}
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '4px',
                                            paddingRight: '0px',
                                            paddingBottom: '4px',
                                        }}
                                    />
                                }
                                label="Incompleto"
                                style={{
                                    margin: 0,
                                    gap: '0px',
                                }}
                            />
                            <Button
                                size="small"
                                style={{ marginLeft: 20 }}
                                disableElevation
                                color={true ? "primary" : undefined}
                                onClick={() => setOpenFilters(current => !current)}
                            >
                                <Icon fontSize="small" style={{ marginRight: 5 }}>{openFilters ? "expand_less" : "expand_more"}</Icon>
                                <span className="pr-2">{`${openFilters ? "Menos filtros" : "Más filtros"}`}</span>
                            </Button>

                            <Tooltip
                                arrow
                                title="Resetear filtros por defecto"
                            >
                                <IconButton
                                    style={{ width: 36, height: 36 }}
                                    onClick={async () => await hanldeClearSearch()}
                                    disabled={initialLoading}
                                >
                                    <Icon style={{ fontSize: 25 }} >
                                        cached
                                    </Icon>
                                </IconButton>
                            </Tooltip>

                            <Button
                                endIcon={<Icon>search</Icon>}
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{
                                    height: '32px',
                                    width: '135px',
                                    fontSize: '13px',
                                    marginLeft: 15
                                }}
                                disableElevation
                                disabled={initialLoading}
                                onClick={async () => {
                                    await handleSearch()
                                }}
                            >
                                {"Buscar"}
                            </Button>
                        </Box>

                    </Box>

                    <Collapse in={openFilters}>
                        <ConciliationFilters
                            initDate={initDate}
                            endDate={endDate}
                            clearInputDates={clearInputDates}
                            handleChangeInitDate={handleChangeInitDate}
                            handleChangeEndDate={handleChangeEndDate}
                            handleSearchByDate={handleSearchByDate}
                        />
                    </Collapse>
                </form>
            </Box >
            <Box className="flex h-full flex-col mt-4 overflow-hidden">
                {
                    initialLoading &&
                    <LinearProgress />
                }
                <Box className="flex flex-grow overflow-auto">
                    <TableContainer>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {CONCILIATIONS_TABLE_COLUMNS.map((headCell) => (

                                        <TableCell
                                            key={headCell.id}
                                            align={"left"}
                                            padding={"default"}

                                        >
                                            <TableSortLabel
                                                style={{
                                                    fontSize: "0.85em", width: (headCell.id === FIELDS.itemText.key) ? 350 : (headCell.id === FIELDS.docDate.key) ? 100 : "max-content",
                                                    textAlign: 'center'
                                                }}
                                                hideSortIcon
                                                disabled
                                            >
                                                {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell padding={"default"} />
                                </TableRow>
                            </TableHead>

                            {conciliations && (
                                <React.Fragment>
                                    <TableBody>
                                        {conciliations.list.map((concilation) => (
                                            <TableRow key={concilation.id}
                                                hover
                                                tabIndex={-1}
                                                className="cursor-pointer"
                                            >
                                                <TableCell component="th" scope="row" padding="default" align="left" >
                                                    {concilation.docDate}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.refDocNumber}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.docHeaderText}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.total}
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.totalSinImpuestos}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.iva}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.assig}
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.itemText}
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.reference1}
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.accessKey}
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="default" align="left">
                                                    {concilation.rucReceptor}
                                                </TableCell>

                                                <TableCell align="center" component="th" scope="row">
                                                    {
                                                        !Boolean(concilation.docHeaderText) && (
                                                            <Tooltip title="Editar">
                                                                <IconButton
                                                                    onClick={
                                                                        () => handleOpenEdit(concilation.id)
                                                                    }
                                                                    size="small">
                                                                    <Icon>create</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </React.Fragment>
                            )}
                        </Table>
                    </TableContainer>
                </Box>
                <Box>
                    {conciliations && (
                        <TablePagination
                            rowsPerPageOptions={MEDIUM_ROWS_PER_PAGINATION_OPTIONS}
                            component="div"
                            count={conciliations.count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Documentos por página"
                            labelDisplayedRows={LabelDisplayedRows}
                        />
                    )}
                </Box>
            </Box >
            <Snackbar
                open={message.open}
                autoHideDuration={6000}
                onClose={() => setMessage({ ...message, open: false })}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Alert
                    variant="filled"
                    onClose={() => setMessage({ ...message, open: false })}
                    severity={message.severity}
                >
                    {message.message}
                </Alert>
            </Snackbar>

            <ConciliationCompleteForm
                open={openForm}
                conciliationId={selectedConciliation}
                handleClose={handleClose}
                addErrorMessage={addErrorMessage}
                addSuccesMessage={addSuccesMessage}
                handleGetConciliations={handleGetConciliations}
            />
        </Paper >
    )
}

export default ConciliationDashboard