import {
    BANK_PATH,
    DASHBOARD_PATH
} from 'lib'
import { ConciliationDashboard } from 'pages'
import React from 'react'
import { Route } from 'react-router-dom'

const Consiliations = () => {
    return (
        <React.Fragment>
            <Route
                strict
                path={`${BANK_PATH}${DASHBOARD_PATH}`}
                component={() => <ConciliationDashboard/>}
            />
           
        </React.Fragment>
    )
}

export default Consiliations
