import { Icon, IconButton, Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import mailSend from 'assets/animations/mail-send.json'
import { DocumentReportBankPicker, EmailList } from 'components'
import { AuthContext } from 'context'
import useBankReportParams from 'hooks/report/useBankReportParams'
import { ROOT_TITLE, SEND_REPORT_TITLE, sendBankReportByEmailService, transformBankReportParams } from 'lib'
import React from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import { IMessageConfig } from 'types'

const SendReports = () => {
    const [selected, setSelected] = React.useState<number[]>([])
    const { user } = React.useContext(AuthContext)
    const { handleChangeParams, params } = useBankReportParams()
    const [message, setMessage] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const toggleSelect = (typeId: number) => {
        setSelected(current => {
            return current.includes(typeId) ? current.filter(c => c !== typeId) : [...current, typeId]
        })
    }

    const addSuccesMessage = (message: string) => {
        setMessage({
            open: true,
            message,
            severity: "info"
        })
    }

    const addErrorMessage = (message: string) => {
        setMessage({
            open: true,
            message,
            severity: "error"
        })
    }

    const resetInputFile = () => {
        handleChangeParams('load_conciliation_exel', {
            ...params.load_conciliation_exel,
            file: null
        })
    }


    const isValidForm = () => {
        if (selected.includes(2)) {
            if (!Boolean(params.load_conciliation_exel.file)) {
                addErrorMessage("Debe seleccionar un archivo primero")
                return false
            }
        }
        return true
    }

    const onSubmit = async (emails: string[]) => {
        try {
            setSelected([])
            if (isValidForm()) {
                await sendBankReportByEmailService(transformBankReportParams(user?.name ?? "", selected, emails, params))
                addSuccesMessage("Sus reportes se estan enviando...")
            }
            resetInputFile()
        } catch (error) {
            addErrorMessage("Error al generar el reporte")
            console.log("Error", error)
        }
    }


    return (
        <div className="w-full flex flex-col h-full overflow-hidden">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${SEND_REPORT_TITLE}`}</title>
            </Helmet>
            <div className="flex flex-grow overflow-hidden">
                <DocumentReportBankPicker
                    selected={selected}
                    toggleSelect={toggleSelect}
                    onChange={handleChangeParams}
                    params={params}
                />
                <EmailList
                    reportTypeSelected={Boolean(selected.length)}
                    onSubmit={onSubmit}
                />
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={message.open}
                autoHideDuration={6000}
                onClose={() => setMessage({ ...message, open: false })}
                action={
                    <IconButton
                        style={{ marginRight: 20 }}
                        aria-label="close"
                        color="inherit"
                        onClick={() => setMessage({ ...message, open: false })}>
                        <Icon>close</Icon>
                    </IconButton>
                }
            >
                <Alert variant="filled"
                    onClose={() => setMessage({ ...message, open: false })}
                    severity={message.severity}
                    style={{ paddingRight: 30 }}>
                    <div className="flex items-center">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: mailSend,
                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                            }}
                            height={120}
                            width={120}
                        />
                        <Typography className="px-4">{message.message}</Typography>
                    </div>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SendReports
