import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core"
import { MEDIUM_ROWS_PER_PAGINATION_OPTIONS, updateConciliationsService } from "lib"
import React from "react"

type Props = {
    open: boolean
    conciliationId: number
    handleClose: () => void
    addErrorMessage: (message: string) => void
    addSuccesMessage: (message: string) => void
    handleGetConciliations: (page: number, rowsPerPage: number, refDocNumber: string, docHeaderText: string, incomplete: boolean, initDate: Date | null, endDate: Date | null) => Promise<void>
}

const ConciliationCompleteForm = (props: Props) => {
    const {
        conciliationId,
        open,
        handleClose,
        addErrorMessage,
        addSuccesMessage,
        handleGetConciliations
    } = props

    const [docHeaderText, setDocHeaderText] = React.useState<string>('')
    const [loadingUpdate, setLoadingUpdate] = React.useState<boolean>(false)

    const handleEditConciliation = async () => {
        try {
            if (docHeaderText.trim().length <= 0) {
                addErrorMessage('Ingrese el Doc Header Text.')
            } else {
                setLoadingUpdate(true)
                const response = await updateConciliationsService(conciliationId, docHeaderText)
                if (response.status === 200) {
                    addSuccesMessage("Conciliación actualizada")
                }
                await handleGetConciliations(0, MEDIUM_ROWS_PER_PAGINATION_OPTIONS[0], '', '', false, null, null)
                setLoadingUpdate(false)
                handleClose()
            }
        } catch (error) {
            addErrorMessage('Error al actualizar la conciliación.')
        }
    }

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        await handleEditConciliation()
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>Conciliación Bancaria</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }} >
                    <DialogContentText>
                        Por favor, completa el Doc Header Text de la Conciliación Bancaria
                    </DialogContentText>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center">
                        <Grid item xs={6}>
                            <TextField
                                variant="standard"
                                classes={{ root: "search-text-field" }}
                                margin="dense"
                                label="Documento Header Text"
                                name="Documento Header Text"
                                fullWidth
                                value={docHeaderText}
                                onChange={(e) => setDocHeaderText(e.target.value)}
                                required
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions >


                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                            height: '32px',
                            width: '135px',
                            fontSize: '13px',
                            marginLeft: 15
                        }}
                        onClick={handleClose} >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                            height: '32px',
                            width: '160px',
                            fontSize: '13px',
                            marginLeft: 15
                        }}
                        disabled={loadingUpdate}
                        onClick={async (event) => {
                            setDocHeaderText('')
                            await onSubmit(event)

                        }}>
                        {'Guardar Cambios'}
                    </Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}

export default ConciliationCompleteForm

