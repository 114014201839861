import { FormControlLabel, Radio, Typography } from '@material-ui/core'
import BankReportParams from 'components/BankReportParams/BankReportParams'
import { REPORT_BANK_TYPES } from 'lib'
import { IBankReportParams } from 'types'

interface Props {
    selected: number[]
    toggleSelect: (typeId: number) => void
    onChange: (key: string, value: any) => void
    params: IBankReportParams
}

const ReportBankTypePicker = (props: Props) => {

    const { selected, toggleSelect, onChange, params } = props

    return (
        <div className="flex flex-grow flex-col h-full mr-4 bg-transparent">
            <Typography className="pb-3 pt-4" color="textSecondary" variant="body2" style={{ fontWeight: 700, letterSpacing: 0.5 }}>
                {"Reportes y parámetros de Bancos"}
            </Typography>
            <div className="flex flex-grow flex-col">
                 {
                    REPORT_BANK_TYPES.map(reportType => (
                        <div className="py-6 pl-4">
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={selected.includes(reportType.id)}
                                        size="small"
                                        onClick={() => toggleSelect(reportType.id)}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="button"
                                        style={{ letterSpacing: 0.5 }}
                                    >
                                        {reportType.name}
                                    </Typography>
                                }
                            />
                            <BankReportParams
                                type={reportType.type}
                                disabled={!selected.includes(reportType.id)}
                                reportKey={reportType.key}
                                onChange={onChange} 
                                params={params} />
                        </div>
                    ))
                } 
            </div>
        </div>
    )
}

export default ReportBankTypePicker
