import { Box, Button, Icon, IconButton, InputAdornment, Tooltip, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import FileUploadDialog from 'components/FileUploadDialog/FileUploadDialog'
import { FIELDS } from 'lib'
import React from 'react'
import { IBankReportParams, IUploadDocumentFile } from 'types'


type ParamsType = "year-month" | "state-types-documentDate-receptionDate"

interface Props {
    type: ParamsType | string
    disabled: boolean
    reportKey: string
    onChange: (key: string, value: any) => void
    params: IBankReportParams
}

const mediumInputStyle = { width: "49%" }

const BankReportParams = (props: Props) => {
    const { type, disabled, params, reportKey, onChange } = props
    const [open, setOpen] = React.useState<boolean>(false)

    const file = params.load_conciliation_exel.file

    const existFile = React.useMemo(() => {
        return Boolean(file)
    }, [file])



    const uploadExelConciliationFile = (acceptedFiles: IUploadDocumentFile[]) => {
        try {
            const file = acceptedFiles[0].file
            onChange(
                reportKey,
                { ...params[reportKey], file }
            )
        } catch (error) {
            console.log("Error", error)
        }
    }

    const handleDeleteFile = () => {
        onChange(
            reportKey,
            { ...params[reportKey], file: null }
        )
    }

    return (
        <div className="w-full flex flex-wrap pt-4">
            {
                type === "documentDate-receptionDate" &&
                <React.Fragment>
                    <div
                        className="flex justify-between mb-2 flex items-start"
                        style={{ ...mediumInputStyle, width: "100%" }}
                    >
                        <KeyboardDatePicker
                            value={params[reportKey].documentStart}
                            helperText={FIELDS.documentDate.name}
                            FormHelperTextProps={{ style: { margin: 0 } }}
                            onChange={(date) => {

                                onChange(
                                    reportKey,
                                    { ...params[reportKey], documentStart: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0, marginRight: 10 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.startDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params[reportKey].documentStart || isNaN(params[reportKey].documentStart.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params[reportKey], documentStart: new Date() }
                                    )
                                }
                            }}
                        />
                        <KeyboardDatePicker
                            value={params[reportKey].documentEnd}
                            onChange={(date) => {
                                onChange(
                                    reportKey,
                                    { ...params[reportKey], documentEnd: date }
                                )
                            }}
                            style={{ ...mediumInputStyle, marginTop: 0 }}
                            emptyLabel={FIELDS.emptyDate.name}
                            inputVariant="outlined"
                            fullWidth
                            invalidDateMessage={"Fecha inválida"}
                            disabled={disabled}
                            size="small"
                            label={FIELDS.endDate.name}
                            variant="inline"
                            format="dd/MM/yyyy"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon fontSize="small" color="action">event</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            onBlur={() => {
                                if (!params[reportKey].documentEnd || isNaN(params[reportKey].documentEnd.getTime())) {
                                    onChange(
                                        reportKey,
                                        { ...params[reportKey], documentEnd: new Date() }
                                    )
                                }
                            }}
                        />
                        <Tooltip arrow title={"Reiniciar fechas"}>
                            <IconButton
                                onClick={() => {
                                    onChange(
                                        reportKey,
                                        { ...params[reportKey], documentEnd: new Date(), documentStart: new Date() }
                                    )
                                }}
                            >
                                <Icon fontSize="small">{"clear_all"}</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>

                </React.Fragment>
            }

            {
                reportKey === "load_conciliation_exel" &&
                <Box className="ml-1.5 pt-2">

                    {
                        !existFile ? (
                            <Button
                                disabled={disabled}
                                disableElevation
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => setOpen(true)}
                                style={{ paddingLeft: "0.85rem", height: 35, width: 260 }}
                            >
                                {"Subir excel de Conciliación"}
                                <Icon fontSize="small" className="ml-1">
                                    upload
                                </Icon>
                            </Button>
                        ) : (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0.5rem 1rem",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    backgroundColor: "#f5f5f5",
                                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        marginRight: "0.75rem",
                                        color: "#333",
                                    }}
                                >
                                    <strong>Archivo seleccionado:</strong> {file!.name}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteFile()}
                                    style={{
                                        color: "#ff6b6b",

                                    }}
                                >
                                    <Tooltip title="Borrar Archivo">
                                        <Icon fontSize="small">close</Icon>
                                    </Tooltip>

                                </IconButton>
                            </Box>
                        )
                    }

                    <FileUploadDialog
                        open={open}
                        onClose={() => setOpen(false)}
                        onAccept={(files) => uploadExelConciliationFile(files)}
                        singleFile
                        hideDescription
                        validateConciliation
                    />
                </Box>
            }

        </div>
    )
}

export default BankReportParams
