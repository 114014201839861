import { AuthContext } from "context"
import { DOCUMENT_PATH, REJECTED_PATH, REPORT_PATH, SEND_PATH } from "lib"
import { SendBankReports, SendDocumentReports, SendRejectedReports } from "pages"
import React from "react"
import { Route } from "react-router-dom"

const Reports = () => {
    const { user } = React.useContext(AuthContext)
    return (
        <React.Fragment>
            {
                Boolean(user) &&
                <React.Fragment>
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${DOCUMENT_PATH}`} component={SendDocumentReports} />
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${DOCUMENT_PATH}`} component={SendDocumentReports} />
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${REJECTED_PATH}`} component={SendRejectedReports} />
                    <Route strict path={`${REPORT_PATH}${SEND_PATH}${'/bank'}`} component={SendBankReports} />

                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Reports
