import { Box, Divider, Icon, IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FIELDS } from 'lib'

interface Props {
    initDate: Date | null
    endDate: Date | null
    clearInputDates: () => void
    handleChangeInitDate: (inicioPromo: Date | null) => void
    handleChangeEndDate: (finPromo: Date | null) => void
    handleSearchByDate: (inicioPromo: Date | null, finPromo: Date | null) => Promise<void>
}

const mediumInputStyle = { width: "49.5%", marginBottom: 15 }

const ConciliationFilters = (props: Props) => {

    const { initDate, endDate, clearInputDates, handleChangeInitDate, handleChangeEndDate, handleSearchByDate } = props

    return (
        <Box className="w-full flex flex-wrap pt-4 justify-between">
            <Box className="w-full mr-1 mb-2">
                <Divider />
            </Box>
            <Box
                className="flex justify-between items-start"
                style={{ ...mediumInputStyle }}
            >
                <KeyboardDatePicker
                    value={initDate}
                    onChange={async (date) => {
                        await handleSearchByDate(date, null)
                        handleChangeInitDate(date)
                    }}
                    style={{ ...mediumInputStyle, marginTop: 0 }}
                    emptyLabel={FIELDS.emptyDate.name}
                    inputVariant="outlined"
                    fullWidth
                    size="small"
                    label={FIELDS.startDate.name}
                    variant="inline"
                    format="dd/MM/yyyy"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon fontSize="small" color="action">event</Icon>
                            </InputAdornment>
                        ),
                    }}
                    helperText={FIELDS.docDate.name}
                />
                <KeyboardDatePicker
                    value={endDate}
                    emptyLabel={FIELDS.emptyDate.name}
                    onChange={async (date) => {
                        await handleSearchByDate(null, date)
                        handleChangeEndDate(date)
                    }
                    }
                    style={{ ...mediumInputStyle, marginTop: 0, marginLeft: "1%" }}
                    inputVariant="outlined"
                    fullWidth
                    size="small"
                    label={FIELDS.endDate.name}
                    variant="inline"
                    format="dd/MM/yyyy"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon fontSize="small" color="action">event</Icon>
                            </InputAdornment>
                        ),
                    }}
                    helperText={FIELDS.docDate.name}
                />
                <Tooltip arrow title={"Borrar fechas"}>
                    <IconButton
                        onClick={() => {
                            clearInputDates()
                        }}
                    >
                        <Icon fontSize="small">clear_all</Icon>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

export default ConciliationFilters
