
export type IConciliationBanckRequest = {
    offset: number
    pageSize: number
    refDocNumber: string
    docHeaderText: string
    initDate: Date
    endDate: Date
    incomplete: boolean
}

export enum LOADING_OPTION_CONCILIATIONS {
    LOADING_CONCILIATIONS = 'loadingConciliations',
    LOADING_CLEAR_SEARCH_ = 'loadingClearSearch',

}

export type IBankDashboardResponse = {
     count: number
     list:IConciliationBackResponse[]
}

export type IConciliationBackResponse = {
     docDate:string
     refDocNumber:string
     docHeaderText:string
     total:number
     totalSinImpuestos:number
     iva:number
     assig:string
     itemText:string
     reference1: string
     accessKey: string
     rucReceptor:string
     id:number
}
