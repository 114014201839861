import axios, { AxiosError } from 'axios'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { IBankDashboardResponse } from 'types'
import {
    BANK_PATH,
    LIST_PATH,
    UPDATE_PATH
} from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

export const getConciliationsService = async (
        page: number,
        rowsPerPage: number,
        refDocNumber: string,
        docHeaderText: string,
        incomplete:boolean,
        initDate: Date | null,
        endDate: Date | null
) => {
    try {
        const result = await intance.post(BANK_PATH + LIST_PATH, {
            offset: page,
            pageSize:rowsPerPage ,
            refDocNumber ,
            docHeaderText ,   
            initDate ,
            endDate,
            incomplete
        })
        return result.data as IBankDashboardResponse
    } catch (error: any) {
        throw new Error((error as AxiosError).message)
    }
}

export const updateConciliationsService = async (
        id: number,
        docHeaderText: string,
) => {
    try {
        const result = await intance.post(BANK_PATH + UPDATE_PATH, {
            id,
            code:docHeaderText,   
        })
        return result
    } catch (error: any) {
        throw new Error((error as AxiosError).message)
    }
}





