import React from 'react'
import { IBankReportParams } from 'types'

interface Props {
}

interface Hook {
    params: IBankReportParams
    handleChangeParams: (key: string, value: any) => void
}

const useBankReportParams = (props?: Props): Hook => {
    const [params, setParams] = React.useState<IBankReportParams>({
        invoices: {
            documentEnd: new Date(),
            documentStart: new Date(),
        },
        load_conciliation_exel: {
            file: null
        }
    })
    const handleChangeParams = (key: string, value: any) => {
        setParams(current => ({
            ...current,
            [key]: value
        }))
    }
    return {
        params,
        handleChangeParams
    }
}

export default useBankReportParams
